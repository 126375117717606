import React from 'react'
import { cell, left, center, right } from './ContentGrid.module.scss'

const ContentGrid = React.memo(({ children, posX, posY }) => {
  const align = (posX) => {
    switch (posX) {
      case 'center':
        return center
      case 'right':
        return right
      default:
        return left
    }
  }

  return (
    <div
      className={`iliad__section--content-container iliad__section--content-container-posY-${posY}`}>
      <div className={['iliad__section--content', cell, align(posX)].join(' ')}>
        {children}
      </div>
    </div>
  )
})

export default ContentGrid
